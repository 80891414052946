









































































































































import { Component, Inject, Vue } from 'vue-property-decorator'
import { ProjectViewModel } from '../viewmodel/project-viewmodel'

@Component
export default class BannerProject extends Vue {
  @Inject() vm!: ProjectViewModel
}
